<template>
  <div class="">
    <van-steps direction="vertical" :active="list.length-1"  finish-icon="checked" >

      <!-- 项 -->
      <van-step v-for="(item, index) in list" :key="index">
        <p >【状态】{{ item.statusRemarks }}</p>
        <p class="m_t_8 ">{{ item.gmtCreate }}</p>
      </van-step>

      <!--  -->
    </van-steps>

    <!-- 缺省 -->

    <van-empty  v-if="!list.length" description="暂无数据"  style="height: calc(100vh - 56vw)"  class="empty-info" />

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

import { incomingPartsOrderNoLogsList } from '@/api/index';

export default {
  components: {
    NoData: () => import('@/components/NoData.vue'),
  },
  props: {
    form: Object,
  },
  data() {
    return {
      list: [{statusRemarks:"预审通过",gmtCreate:"2023-09-12"},
        {statusRemarks:"初审通过",gmtCreate:"2023-09-12"},
        {statusRemarks:"通过",gmtCreate:"2023-09-12"},
        {statusRemarks:"通过",gmtCreate:"2023-09-12"},
        {statusRemarks:"通过",gmtCreate:"2023-09-12"},
        {statusRemarks:"通过",gmtCreate:"2023-09-12"},
        {statusRemarks:"通过",gmtCreate:"2023-09-12"},

      ],

      //
    };
  },
  computed: {},
  mounted() {
    this.getList();

    //
  },
  methods: {
    // 获取 列表
    async getList() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.list = await incomingPartsOrderNoLogsList(
          this.$props.form.orderNo
        );

        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    //
  },
};
</script>
<style>
.van-step--finish{
  color: #07c160;
}
</style>
